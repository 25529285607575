/*===================== 
	Premery bg color 
=====================*/

:root {
  --color-primary: #6f4e37;
  --color-secondry: #daa06d;
  --color-hover: #daa06d;
}

/* Hex */
.bg-primary,
.theme-bg,
.meet-ask-row:after,
.dlab-separator.style-skew[class*="style-"]:after,
.dlab-separator.style-skew[class*="style-"]:before,
.dlab-tilte-inner.skew-title:after,
.date-style-2 .post-date,
.date-style-3.skew-date .post-date,
.date-style-3.skew-date .post-date:before,
.overlay-primary-light:after,
.overlay-primary-middle:after,
.overlay-primary-dark:after,
.widget-title:after,
.btn,
.comments-area .comment-form p input[type="submit"],
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus,
.footer-line:after,
.testimonial-1 .quote-left:before,
.testimonial-2 .quote-left:before,
.site-filters .active > [class*="btn"],
.list-num-count > li:before,
.date-style-4 .post-date strong,
.date-style-3 .post-date,
.date-style-2 .post-date,
#myNavbar li.active a,
.skew-angle:after,
.header-style-4.style-1 .header-nav .nav > li.active a:before,
.header-style-4.style-1 .header-nav .nav > li:hover > a:before,
.dlab-project-left:after,
.service-carousel .owl-prev,
.service-carousel .owl-next,
.about-us .dlab-tabs .nav-tabs li.active a,
.testimonial-text.bg-primary,
.sidenav ul li a:hover,
.owl-theme.dots-style-2 .owl-dots .owl-dot.active span,
.owl-btn-1.primary .owl-prev,
.owl-btn-1.primary .owl-next,
.owl-btn-2.primary .owl-prev,
.owl-btn-2.primary .owl-next,
.owl-slider-banner .owl-theme .owl-dots .owl-dot.active span,
.owl-slider-banner .owl-theme .owl-dots .owl-dot:hover span,
.product-item-size .btn.active,
.ui-widget-header,
.owl-theme.dots-style-3 .owl-dots .owl-dot.active span,
.owl-theme.dots-style-3 .owl-dots .owl-dot:hover span,
.owl-item.active.center .testimonial-text,
.icon-bx-wraper.hover:hover,
.portfolio-sep,
.portfolio-ic .dlab-img-overlay1:before,
.dlab-box-bg.active .icon-box-btn .btn,
.dlab-accordion.primary .acod-head a,
.widget_getintuch ul li:hover i,
.social-list li a:hover,
.btn.white:hover,
.widget.widget_tag_cloud .tagcloud a,
.dlab-post-tags .post-tags a,
.fc-day-header.fc-widget-header,
.fc-button.fc-state-default:hover,
.fc-button.fc-state-down,
.fc-button.fc-state-active,
.fc-button.fc-state-default {
  background-color: #6f4e37;
}

/*Hex color :hover */
.btn:active,
.btn:hover,
.btn:focus,
.active > .btn,
.bg-primary-dark,
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus,
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus,
.sidenav .closebtn:hover,
.sidenav .closebtn:focus,
.owl-btn-1.primary .owl-prev:hover,
.owl-btn-1.primary .owl-next:hover,
.owl-btn-2.primary .owl-prev:hover,
.owl-btn-2.primary .owl-next:hover {
  background-color: #daa06d;
}
.about-us .dlab-tabs .nav-tabs li.active {
  border-right: 4px solid #daa06d;
}
/* Rgba color */
.dlab-info-has.bg-primary,
.dlab-info-has.bg-secondry:hover,
.tp-bannertimer.tp-bottom {
  background-color: rgba(26, 188, 156, 0.9);
}
.dlab-social-icon.dlab-social-icon-lg li a:focus,
.dlab-social-icon.dlab-social-icon-lg li a:hover {
  background-color: #daa06d !important;
}
/*===================== 
	Premery text color 
=====================*/
a,
.text-primary,
.primary li:before,
.breadcrumb-row ul li a,
.header-nav .nav > li.active > a,
.header-nav
  .nav
  > li.current-menu-item
  > a
  .header-nav
  .nav
  > li
  .sub-menu
  li
  a:hover,
.header-nav .nav > li:hover > a,
.header-nav .nav > li .mega-menu > li ul a:hover,
.header-nav .nav > li .sub-menu li:hover > a,
.nav-dark.header-nav .nav > li .sub-menu li:hover > a,
.nav-dark.header-nav .nav > li .mega-menu > li ul a:hover,
blockquote:before,
ol.comment-list li.comment .reply a,
footer a:active,
footer a:focus,
footer a:hover,
footer h1 a,
footer h2 a,
footer h3 a,
footer h4 a,
footer h5 a,
footer h6 a,
footer p a,
button.scroltop,
.testimonial-1 .testimonial-position,
.testimonial-4 .testimonial-name:after,
.testimonial-2 .testimonial-position,
.testimonial-3 .testimonial-position,
.testimonial-3 .quote-left:before,
.acod-head a:after,
.acod-head a,
.acod-head a:hover,
.acod-head a.collapsed:hover,
.dlab-tabs .nav-tabs > li > a i,
h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover,
.btn.outline,
.btn-link:hover,
.item-info-in ul li a:hover,
.dlab-post-meta.text-primary i,
.dlab-post-meta.text-primary ul li,
.dlab-post-meta.text-primary ul li a,
.dlab-post-meta i,
.comments-area p:before,
.about-8-service .icon-bx-wraper:hover a,
.header-nav .nav > li > a.nav-link.active {
  color: #6f4e37;
}
.text-primary {
  color: #6f4e37 !important;
}
@media only screen and (max-width: 991px) {
  .nav-dark.header-nav .nav > li > a:hover,
  .nav-dark.header-nav .nav > li > a:active,
  .nav-dark.header-nav .nav > li > a:focus {
    color: #daa06d;
  }
}
/*===================== 
	Premery border color 
=====================*/
.navbar-toggle,
.testimonial-4 .testimonial-pic,
.testimonial-4:after,
.testimonial-4 [class*="quote-"],
button.scroltop,
.widget_gallery li:hover,
.owl-slider-banner .owl-theme .owl-dots .owl-dot.active,
.owl-slider-banner .owl-theme .owl-dots .owl-dot:hover,
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
input[type="checkbox"]:checked + label:before,
input[type="checkbox"] + label:hover:before,
input[type="radio"] + label:hover:before,
.owl-theme.dots-style-3 .owl-dots .owl-dot.active,
.owl-theme.dots-style-3 .owl-dots .owl-dot:hover,
.side-bar .widget_tag_cloud a:hover {
  border-color: #daa06d;
}
.owl-item.active.center .testimonial-8 .testimonial-text:after {
  border-top: 25px solid #daa06d;
}
.dlab-tabs.border-top .nav-tabs > li.active > a {
  border-top-color: #daa06d;
}
/*===================== 
	Secondery bg color
 =====================*/
.bg-secondry {
  background-color: #141719;
}

/*===================== 
	Secondery text color
 =====================*/
.text-secondry {
  color: #141719;
}

/* white css */
.site-header.header-black .header-nav .nav > li > a {
  color: #000 !important;
}
.footer-copyright p,
.site-footer .widget.widget_about .footer-title,
.split-content .description,
.services-box .description,
.split-content .text-white,
.split-content .title,
.page-banner .video-box .content-box .social-list ul li a,
.page-banner .video-box .content-box p,
.page-banner .video-box .content-box .title,
.site-header.header-black .video-wraper .video-play a span {
  color: #000 !important;
}
.social-section .title a,
.site-footer .widget.widget_about .footer-title a,
.site-footer .social-link li a span {
  background-image: linear-gradient(90deg, #ff0000, #ff0000 50%, #000 0);
}
.site-footer .footer-bottom-in {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.bg-primary {
  background-color: #6f4e37 !important;
}

/* Text Selection */
::selection {
  background: #6f4e37;
  color: #fff;
}
::-moz-selection {
  background: #6f4e37;
  color: #fff;
}
